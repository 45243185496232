/* -----------------------------------------
   Loading Page
  -------------------------------------------- */

.load-wrapp {
  width: 100%;
  min-height: 70vh;
}

.load-cont {
  width: 80%;
  margin: 5rem auto;
  height: fit-content;
}

.letter-holder {
  width: fit-content;
  margin: auto;
  height: 25vw;
  padding: 16px;
}

.letter {
  float: left;
  font-size: 2rem;
  color: rgb(255, 255, 255);
}
.load-cont .letter {
  animation-name: loadingF;
  animation-duration: 1.6s;
  animation-iteration-count: infinite;
  animation-direction: linear;
}

.l-1 {
  animation-delay: 0.48s;
}
.l-2 {
  animation-delay: 0.6s;
}
.l-3 {
  animation-delay: 0.72s;
}
.l-4 {
  animation-delay: 0.84s;
}
.l-5 {
  animation-delay: 0.96s;
}
.l-6 {
  animation-delay: 1.08s;
}
.l-7 {
  animation-delay: 1.2s;
}
.l-8 {
  animation-delay: 1.32s;
}
.l-9 {
  animation-delay: 1.44s;
}
.l-10 {
  animation-delay: 1.56s;
}

@keyframes loadingF {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.spinner {
  position: relative;
  width: 5rem;
  height: 5rem;
  margin: 0 auto;
}

.bubble-1,
.bubble-2,
.bubble-3,
.bubble-4 {
  position: absolute;
  top: 0;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  background-color: #4b9cdb;
}

.bubble-2 {
  background-color: red;
  top: auto;
  bottom: 0;
}
.bubble-3 {
  background-color: green;
  right: 0;
}
.bubble-4 {
  background-color: yellow;
  bottom: 0;
  right: 0;
  top: auto;
}

.load-cont .spinner {
  animation: loadingI 2s linear infinite;
}
.load-cont .bubble-1,
.load-cont .bubble-2,
.load-cont .bubble-3,
.load-cont .bubble-4 {
  animation: bounce 4s ease-in-out infinite;
}
.load-cont .bubble-2 {
  animation-delay: -1s;
}
.load-cont .bubble-3 {
  animation-delay: -2s;
}
.load-cont .bubble-4 {
  animation-delay: -1s;
}

@keyframes loadingI {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

@media only screen and (max-width: 500px) {
  .load-wrapp {
    min-height: 60vh;
  }
}

.quick-facts {
  margin-top: 17px;
  color: #f8f8ff;
  font-size: 2rem;
}
.dashBoard-cont {
  display: flex;
  margin: 17px 0;
  padding: 15px 0;
  color: rgba(0, 0, 0, 1);
}
.row h3 {
  margin: auto;
  position: relative;
  padding: 0.25rem 0;
  font-size: 1.15rem;
}
.dashboard-africa-left {
  width: 40%;
}
.dashboard-africa-left img {
  width: 40%;
  margin: auto;
  position: relative;
  filter: grayscale(90%);
}
.dashboard-africa-right {
  width: 60%;
  text-align: left;
}

.dashboard-africa-right .row {
  margin: auto;
  position: relative;
  color: #f8f8ff;
}
.dashboard-africa-right .row .col-7 {
  padding: 2px 0px 2px 7px;
}
.dashboard-africa-right .row .col-5 {
  padding: 2px 7px 2px 0;
}

.cases,
.tests,
.death {
  border-radius: 16px;
  font-size: 1.15rem;
}
.cases {
  color: rgb(252, 223, 127);
}
.death {
  color: rgb(248, 110, 121);
}
.tests {
  color: rgb(102, 226, 131);
}

@media screen and (max-width: 768px) {
  .row h3 {
    font-size: 1.15rem;
    font-weight: 700;
  }
  .dashboard-africa-left {
    display: none;
  }
  .dashboard-africa-right {
    width: 100%;
  }
  .cases,
  .death,
  .tests {
    text-align: right;
    margin: 4px 0;
  }
}

@media screen and (max-width: 500px) {
  .quick-facts {
    font-size: 1.25rem;
    margin-top: 8px;
  }

  .dashboard-africa-right .row h3 {
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.05rem;
  }

  .dashBoard-cont {
    margin-top: 0;
    margin-bottom: 0;
    padding: 8px 0;
  }
  .dashboard-africa-right .row h3 {
    margin: auto;
    position: relative;
  }
}
@media screen and (min-width: 900px) {
  .dashboard-africa-left img {
    width: 40%;
  }
}
@media screen and (max-width: 375px) {
  .dashboard-africa-right .row h3 {
    font-size: 0.875rem;
    font-size: 300;
  }
  .cases,
  .death,
  .tests {
    text-align: right;
    margin: 0;
  }
}

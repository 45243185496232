.dashboardroute-cont {
  margin-bottom: 2rem;
}
table {
  width: 100%;
  margin-top: 12px;
}
.table-cont {
  background-color: #282c35;
  border-radius: 28px;
  padding: 1rem;
  padding-top: 2rem;
}
.table-cont h3 {
  font-size: 1.25rem;
  font-weight: 300;
  color: white;
}
.table-cont small {
  font-style: italic;
  font-size: 70%;
  font-weight: 300;
  color: white;
}
.table-cont a {
  color: white;
}
.table-cont a:hover {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}
.table-cont table thead tr th {
  border-bottom: 0px;
  border-top: 0px;
}
thead tr:nth-child(1) th {
  border-radius: 16px 16px 0 0;
}
thead tr:nth-child(2) th:nth-child(odd) {
  border-radius: 0px 0px 0px 16px;
}
thead tr:nth-child(2) th:nth-child(even) {
  border-radius: 0px 0px 16px 0px;
}
thead tr:nth-child(2) th:nth-last-child(1) {
  border-radius: 0px 0px 16px 16px;
}
thead tr:nth-child(1) th:nth-child(1) {
  border-radius: 16px;
}
thead tr th {
  font-size: 1rem;
}
.table-cont .table thead tr {
  border-collapse: collapse;
}

.table-cont .table tbody tr:hover {
  cursor: pointer;
}
.td-country {
  width: 23%;
  text-align: left;
  color: #f8f8ff;
  text-decoration: none;
}
.td-country span {
  font-weight: 300;
  letter-spacing: 0.075rem;
  position: relative;
}
.td-country span:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #f8f8ff;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.4s ease-in-out;
}
.table-cont .table tbody tr:hover > .td-country span:before {
  visibility: visible;
  transform: scaleX(1);
}

.table-cont thead tr th,
.table-cont tbody tr td {
  border-color: #3b3c36;
}
.table tbody th,
.table tbody td,
.table thead th {
  padding: 0.45rem 0.25rem;
}
.fas {
  padding: 0 0.5rem;
}

.th-cases,
.th-death,
.th-tests,
.th-country {
  background: rgb(223, 221, 221);
  background: radial-gradient(
    circle,
    rgba(223, 221, 221, 1) 45%,
    rgba(230, 230, 255, 1) 120%
  );
  color: rgb(0, 0, 0);
}
.sorted {
  color: rgb(39, 150, 29);
  text-decoration: underline;
}
thead tr:nth-child(2) .th-cases,
thead tr:nth-child(2) .th-death,
thead tr:nth-child(2) .th-tests,
.th-country:hover {
  cursor: pointer;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: rgb(53, 59, 59);
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #282c35;
}
.table td {
  padding: 0 0.75rem;
}

.td-flag {
  width: 7%;
}
.td-flag img {
  border-radius: 50%;
}
.flag {
  width: 80%;
  margin: 0 0.5rem;
}
.td-tdy-cases,
.td-tdy-death,
.td-ttl-death,
.td-ttl-cases,
.td-ttl-tests {
  color: #f8f8ff;
  width: 14%;
  font-weight: 200;
}

thead tr:nth-child(1) th {
  position: sticky;
  top: 0;
}
thead tr:nth-child(2) th {
  position: sticky;
  top: 33px;
}

@media screen and (max-width: 767px) {
  thead tr th {
    font-size: 1rem;
    border-radius: 16px;
  }
  tbody tr td {
    font-size: 1rem;
  }
  tbody tr th {
    font-size: 1rem;
    font-weight: 400;
  }
  .td-flag {
    width: 10%;
  }
  .td-country {
    width: 20%;
  }
  .table-cont h3 {
    font-size: 1rem;
    font-weight: 300;
  }
}

@media screen and (max-width: 500px) {
  .table-cont h3 {
    font-size: 0.85rem;
    font-weight: 300;
  }
  .table-cont small {
    font-style: italic;
    font-size: 70%;
  }
  .table tbody td {
    padding: 0.65rem 0;
  }
  .table-cont a {
    text-decoration: underline;
  }
  .dash .td-flag {
    width: 0%;
  }
  .flag {
    display: none;
  }

  .table-cont {
    padding-top: 16px;
  }
  .td-country span {
    font-size: 1rem;
    font-weight: 400;
    text-decoration: underline;
  }
  .td-tdy-cases,
  .td-tdy-death,
  .td-ttl-death,
  .td-ttl-cases,
  .td-ttl-tests {
    font-size: 0.875rem;
    font-weight: 300;
  }
  .th-cases,
  .th-death,
  .th-tests,
  .th-country {
    font-size: 1rem;
    font-weight: 400;
  }
  .table tbody td,
  .table tbody th,
  .table thead th {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .td-country {
    width: 24%;
  }

  .td-ttl-cases,
  .td-ttl-tests {
    width: 17%;
  }
  .table-cont .table tbody tr:hover > .td-country span:before {
    visibility: hidden;
  }
}
@media screen and (max-width: 425px) {
  .td-flag {
    width: 0%;
  }
  .flag {
    display: none;
  }

  .th-tests,
  .td-ttl-tests {
    width: 0%;
    display: none;
  }
  .td-country {
    width: 14%;
  }
  .td-country span {
    font-size: 0.85rem;
    font-weight: 300;
    letter-spacing: 0rem;
  }
  .td-tdy-cases,
  .td-tdy-death {
    font-size: 0.75rem;
    width: 20%;
    font-weight: 300;
  }
  .td-ttl-death,
  .td-ttl-cases {
    font-size: 0.75rem;
    width: 23%;
    font-weight: 300;
  }
  .th-cases,
  .th-death,
  .th-country {
    font-size: 0.875rem;
    font-weight: 400;
  }
  .fas {
    color: black;
    padding: 0 0.025rem;
  }
}

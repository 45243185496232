.comparison-cont {
  margin-top: 2rem;
  background-color: #282c35;
  border-radius: 5rem;
  padding-top: 2rem;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.comparison-cont h5 {
  color: rgb(223, 219, 219);
}
.search-cont {
  margin-top: 2rem;
  min-height: 70vh;
}
.search-cont .compare-btn {
  background-color: khaki;
  width: 50%;
  margin: 2rem auto;
  position: relative;
}
.search-cont .option-btn {
  color: #fff;
  background-color: #545d81;
}
.comparisondata-cont .back-btn {
  color: rgb(214, 202, 202);
  margin: auto auto 1.5rem auto;
  background-color: #343a40;
  position: relative;
  width: 50%;
}
.comparisondata-cont .back-btn:hover {
  background-color: rgb(126, 113, 113);
}
.comparison-cases {
  margin-top: 1.5rem;
}
.comparisondata-cont h4 {
  color: #fff;
}
.compare-table {
  color: #f8f8ff;
  margin-bottom: 3rem;
  margin-top: 2rem;
}
.compare-table tr td {
  font-size: 0.875rem;
  text-align: left;
  padding-right: 0.5rem;
  font-weight: 300;
}
.compare-table tr th {
  font-size: 1rem;
  text-align: left;
  font-weight: 400;
  padding-bottom: 1rem;
  padding-right: 0.5rem;
}

@media only screen and (max-width: 600px) {
  .compare-table tr > * {
    display: block;
  }
  .compare-table tr {
    display: table-cell;
  }
  .comparison-cont h5 {
    width: 90%;
    margin-bottom: 0;
  }
  .comparison-cases,
  .comparison-deaths {
    height: 60vh;
  }
  .compare-table tr td {
    font-size: 1rem;
    font-weight: 300;
    padding-bottom: 0.5rem;
  }
  .compare-table tr th {
    padding-bottom: 0.25rem;
    font-size: 1rem;
    font-weight: 300;
  }
  .compare-table {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  .comparison-cont {
    margin-top: 1rem;
    border-radius: 2rem;
    padding-top: 1rem;
  }
}
@media only screen and (max-width: 375px) {
  .compare-table tr td {
    font-size: 0.875rem;
    font-weight: 300;
    padding-bottom: 0.15rem;
  }
  .compare-table tr th {
    padding-bottom: 0rem;
    font-size: 0.875rem;
    font-weight: 300;
  }
  .compare-table {
    max-width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .compare-table {
    width: 100%;
  }
  .compare-table tr td {
    font-size: 0.875rem;
    padding-right: 0.5rem;
  }
  .compare-table tr th {
    font-size: 1rem;
    font-weight: 300;
  }
}

.navbar.navbar-dark {
  background-color: #282c35;
}
.navbar-brand img {
  margin-right: 0.25rem;
}
.navbar .container .flatten {
  color: rgb(244, 247, 241);
}
.navbar .container .navbar-brand {
  font-weight: 600;
  letter-spacing: 0.15rem;
}
.navbar .container .navbar-brand:hover {
  font-weight: 900;
}
.navbar .container .comparison-navbar {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
  color: aliceblue;
}
.navbar .container a.comparison-navbar:hover {
  font-weight: 500;
}
.navbar .container .navbar-brand:hover {
  font-weight: 600;
}
.navbar a.navbar-brand {
  position: relative;
}
.navbar a.navbar-brand:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.4s ease-in-out;
}
.navbar a.navbar-brand:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

@media screen and (max-width: 500px) {
  .navbar .container .navbar-brand {
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0rem;
  }
  .navbar .container .comparison-navbar {
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: 0rem;
  }
  .navbar-brand img {
    width: 20px;
    height: 20px;
  }
}
@media screen and (max-width: 800px) {
  .navbar .container .navbar-text {
    display: none;
  }
}

.country-cont {
  margin-top: 1.55rem;
  padding-top: 1.5rem;
  font-size: 1.45rem;
  background-color: #282c35;
  border-radius: 5rem;
}
.country-cont > .row {
  margin-bottom: 2rem;
}
.country-name {
  font-size: 2rem;
  color: white;
}

.country-flag img {
  position: absolute;
  width: 15%;
  border-radius: 50%;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.country-table {
  color: #f8f8ff;
  margin: auto;
  width: 90%;
  position: relative;
  margin-bottom: 4rem;
}
.country-table tr td {
  font-size: 0.875rem;
  text-align: left;
  padding-right: 0.5rem;
  font-weight: 300;
}
.country-table tr th {
  font-size: 1rem;
  text-align: left;
  font-weight: 400;
  padding-bottom: 1rem;
  padding-right: 0.5rem;
}

@media only screen and (max-width: 768px) {
  .country-flag img {
    width: 40%;
  }
  .country-cont {
    font-size: 1rem;
  }
  .country-name {
    font-size: 2.5rem;
  }
  .country-table {
    width: 100%;
  }
  .country-table tr td {
    font-size: 0.875rem;
    padding-right: 0.5rem;
  }
  .country-table tr th {
    font-size: 1rem;
    font-weight: 300;
  }
}

@media only screen and (min-width: 940px) {
  .country-cases,
  .country-deaths {
    width: 90%;
    margin: auto;
  }
}
@media only screen and (max-width: 500px) {
  .country-cont {
    max-width: 93%;
  }
  .country-cases,
  .country-deaths {
    height: 40vh;
    margin-bottom: 0.85rem;
  }
  .country-name {
    font-size: 1.15rem;
  }
  .country-flag img {
    width: 30%;
  }
}
@media only screen and (max-width: 600px) {
  .country-table tr td {
    font-size: 1rem;
    font-weight: 300;
    padding-bottom: 0.5rem;
  }
  .country-table tr th {
    padding-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
  }
  .country-table tr > * {
    display: block;
  }
  .country-table tr {
    display: table-cell;
  }
}
@media only screen and (max-width: 375px) {
  .country-table tr td {
    font-size: 0.875rem;
    font-weight: 300;
    padding-bottom: 0.15rem;
  }
  .country-table tr th {
    padding-bottom: 0.2rem;
    font-size: 0.875rem;
    font-weight: 300;
  }
  .country-cont {
    max-width: 100%;
  }
}
